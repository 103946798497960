import React from "react"
import { PageProps, graphql } from "gatsby"
import { Col } from "antd"
import Img from "gatsby-image"
import "../fonts/MyFontsWebfontsKit.css"
import "./style.css"
import { FixedImage } from "../models/gatsby"
import {
  JesRow,
  HalfCol,
  FullCol,
  LargeCol,
  FillingCol,
  SmallCol,
} from "../components/layout"
import { JesLayout } from "../components/jes-layout"
import {
  LearnMoreLink,
  BigCaption,
  StandardParagraph,
  BLOCKS_BACKGROUND,
  BLOCKS_SHADOW,
  BLOCKS_SPACING,
  PrimaryHeading,
  Legend,
  SecondaryHeading,
  Bold,
} from "../components/blocks"
import { useMediaQuerySafe } from "../components/use-mq-safe"
import { Trans, useI18next } from "gatsby-plugin-react-i18next"

type DataProps = {
  site: {
    buildTime: string
  }
  jesLogo: FixedImage
  jesLogoTop: FixedImage
  kukLogo: FixedImage
  euroartLogo: FixedImage
  holzausen: FixedImage
  jochenUndErika: FixedImage
  kukKunstUndKultur: FixedImage
  kukStartPage: FixedImage
  kukArtists: FixedImage
  kukShowcaseErler: FixedImage
  jugend: FixedImage
}

const HomePage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const [isMobile, setisMobile] = React.useState(false)
  const colSize = useMediaQuerySafe()
  const { t } = useI18next()

  React.useEffect(() => {
    setisMobile(["xs", "sm", "md"].includes(colSize))
  }, [colSize])
  return (
    <JesLayout
      title="Jes Kulturstiftung"
      contentStyle={{ background: "#efe9e4" }}
      footerStyle={{ background: "#efe9e4" }}
    >
      <JesRow noPadding>
        <PrimaryHeading>
          <Trans>Willkommen auf der Website der JES Kulturstiftung</Trans>
        </PrimaryHeading>
      </JesRow>
      <div style={{ padding: "50px 0px" }}>
        <JesRow
          noPadding
          noGutter
          style={{
            background: BLOCKS_BACKGROUND,
            boxShadow: BLOCKS_SHADOW,
            marginBottom: BLOCKS_SPACING,
          }}
          align="stretch"
        >
          <LargeCol>
            <Img
              // style={{ margin: "auto", width: "100%" }}
              fluid={data.jochenUndErika.childImageSharp.fluid}
            />
          </LargeCol>
          <SmallCol
            style={{
              display: "flex",
              padding: `20px`,
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <div
              style={{
                padding: "0 20px",
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <div style={{ flexGrow: 1 }} />
              <BigCaption>
                <Trans>
                  Kunst und Kultur verbindet Menschen.
                  <br /> Das möchten wir gerne fördern.
                </Trans>
              </BigCaption>
              <Legend style={{ textAlign: "right" }}>
                <Trans>Jochen und Erika Seifert</Trans>
              </Legend>
              <div style={{ flexGrow: 1 }} />
              <LearnMoreLink
                to="/stiftung"
                text={t("MEHR ERFAHREN")}
                style={{ display: "block", textAlign: "right" }}
              />
            </div>
          </SmallCol>
        </JesRow>
        <JesRow
          noPadding
          noGutter
          align="stretch"
          rowStyle={{
            flexDirection: isMobile ? "column-reverse" : "row-reverse",
          }}
        >
          <SmallCol>
            <JesRow
              style={{
                background: BLOCKS_BACKGROUND,
                marginBottom: BLOCKS_SPACING,
                boxShadow: BLOCKS_SHADOW,
                padding: "20px",
              }}
              noPadding
              noGutter
              align="top"
            >
              <HalfCol style={{ padding: "0px 10px" }}>
                <Img
                  className="euroArtLogo"
                  style={{
                    maxWidth: "80%",
                    width: "150px",
                    // margin: "auto",
                  }}
                  fluid={data.euroartLogo.childImageSharp.fluid}
                />
              </HalfCol>
              <HalfCol>
                <Legend>
                  <Trans>
                    Mitgliedschaft der Künstlerkolonie Holzhausen im Verbund
                    „euroArt“.
                  </Trans>
                </Legend>
                <br />
                <LearnMoreLink
                  style={{
                    marginLeft: "auto",
                    display: "block",
                    textAlign: "right",
                  }}
                  to="/projekte/euro-art"
                  text={t("MEHR ERFAHREN")}
                />
              </HalfCol>
            </JesRow>

            <JesRow noGutter noPadding>
              <FullCol
                style={{
                  background: BLOCKS_BACKGROUND,
                  boxShadow: BLOCKS_SHADOW,
                }}
                noPaddingOverride
              >
                <div
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <SecondaryHeading
                    style={{
                      color: "#a5998f",
                      display: "inline-block",
                      fontFamily: "Accord-Bold",
                    }}
                  >
                    <Trans>SO KÖNNEN SIE UNS UNTERSTÜTZEN</Trans>
                  </SecondaryHeading>
                  <LearnMoreLink
                    to="/mitmachen"
                    style={{ display: "block", textAlign: "right" }}
                    text={t("WEITER")}
                  />
                </div>
              </FullCol>

              <FullCol
                noPaddingOverride
                style={{
                  background: BLOCKS_BACKGROUND,
                  boxShadow: BLOCKS_SHADOW,
                }}
              >
                <div
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <SecondaryHeading
                    style={{
                      color: "#a5998f",
                      display: "inline-block",
                      fontFamily: "Accord-Bold",
                    }}
                  >
                    <Trans>UNSER ENGAGEMENT IN DER PRESSE</Trans>
                  </SecondaryHeading>
                  <LearnMoreLink
                    to="/presse"
                    style={{ display: "block", textAlign: "right" }}
                    text={t("WEITER")}
                  />
                </div>
              </FullCol>
            </JesRow>
          </SmallCol>
          <Col flex={BLOCKS_SPACING} className="no-mobile"></Col>
          <FillingCol
            style={{
              background: BLOCKS_BACKGROUND,
              boxShadow: BLOCKS_SHADOW,
            }}
            className="margin-top-hp"
          >
            <JesRow
              align="stretch"
              noPadding
              noGutter
              style={{ height: "100%" }}
              rowStyle={{ height: "100%" }}
            >
              <HalfCol
                style={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Img
                  className="kukLogo"
                  style={{ maxWidth: "90%", width: "300px", margin: "auto" }}
                  fluid={data.kukLogo.childImageSharp.fluid}
                />

                <div
                  style={{
                    padding: "20px",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ flexGrow: 1 }}></div>
                  <StandardParagraph
                    style={{ padding: "20px 0", fontSize: "18px" }}
                  >
                    <Trans>
                      Die Website
                      <Bold> www.kuk.art </Bold>
                      ist ein interaktives Webprojekt, das die Kunstgeschichte
                      der Künstlerkolonie Holzhausen am Ammersee sichtbar und
                      erlebbar macht.
                    </Trans>
                  </StandardParagraph>
                  <div style={{ flexGrow: 1 }}></div>

                  <LearnMoreLink
                    style={{ textAlign: "right", display: "block" }}
                    to="/projekte/kuk"
                    text={t("MEHR ERFAHREN")}
                  />
                </div>
              </HalfCol>
              <HalfCol style={{ background: "black" }}>
                <Img
                  style={{ width: "100%", height: "100%" }}
                  fluid={data.kukStartPage.childImageSharp.fluid}
                />
              </HalfCol>
            </JesRow>
          </FillingCol>
        </JesRow>
      </div>
    </JesLayout>
  )
}
export default HomePage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    jesLogo: file(relativePath: { eq: "jes_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jochenUndErika: file(relativePath: { eq: "jochenunderikaseifert.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jesLogoTop: file(relativePath: { eq: "jes_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 375) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukLogo: file(relativePath: { eq: "kuk_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    euroartLogo: file(relativePath: { eq: "euroart_logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    holzausen: file(relativePath: { eq: "holzhausen_postkarte.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukStartPage: file(relativePath: { eq: "kuk-startpage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukShowcaseErler: file(relativePath: { eq: "kukart-showcase-erler.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukArtists: file(relativePath: { eq: "kuk_artists_page.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kukKunstUndKultur: file(relativePath: { eq: "kuk-kunstundkultur.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    jugend: file(relativePath: { eq: "jugend.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
